import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Custom components
import Title from "../../../../general/Title";
import TopBarForm from "../TopBarForm";
import InstructionDialog from "../InstructionDialog";

// Mui core
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import IconButton from "@mui/material/IconButton";

// Hooks
import { useFetchUser } from "../../../../../hooks/useFetchUser";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import { dirtyValues, removeUndefinedInObject } from "../../../../../utils/general";

// Icons
import { GrCircleInformation } from "react-icons/gr";
import { MdKeyboardArrowRight } from "react-icons/md";
import { getScoreByQuestion } from "../../../../../utils/score";
import { Participant } from "../../../../../interfaces/Participant";

const requiredMessage = "Required field";

interface Props {
    // eslint-disable-next-line
    onComplete: (title: string, data: any, points: number, topic: string, participant?: Participant | undefined) => void;
}

const TITLE_FR = "Votre sommeil";
const TITLE_EN = "Your Sleep";
const ABOUT_FR =
    "Ce questionnaire nous aidera à mieux vous connaître et à suivre votre progression. Dix minutes devraient être nécessaires pour le compléter. Essayez de terminer le questionnaire en une seule séance.";
const ABOUT_EN =
    "These questions will help us to know you better and see your progress. This should take about 10 minutes. Try to complete this task in one session.";
const INSTRUCTION_FR = "Parlez-nous de votre sommeil au cours des trois derniers mois. Répondez à chaque question au mieux de vos capacités.";
const INSTRUCTION_EN = "Tell us about your sleep over the last three months. Answer each question to the best of your abilities.";

const SleepForm: React.FC<Props> = ({ onComplete }) => {
    const { setLoading } = useFetchUser();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [showQuestion5, setShowQuestion5] = useState(false);
    const [showQuestion7, setShowQuestion7] = useState(false);
    const [showQuestion9, setShowQuestion9] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [isSafeToReset, setIsSafeToReset] = useState(false);

    const generateSchema = () => {
        const output: any = {};
        output.question2 = yup.string().required(requiredMessage);
        output.question3 = yup.number().min(1).max(24).required(requiredMessage);
        output.question4 = yup.string().required(requiredMessage);
        if (showQuestion5) output.question5 = yup.string().required(requiredMessage);
        output.question6 = yup.string().required(requiredMessage);
        if (showQuestion7) output.question7 = yup.string().required(requiredMessage);
        output.question8 = yup.string().required(requiredMessage);
        if (showQuestion9) output.question9 = yup.string().required(requiredMessage);
        output.question10 = yup.string().required(requiredMessage);
        output.question11 = yup.number().min(0).max(1440).required(requiredMessage);

        return yup.object(output);
    };

    const generateDefaultValues = () => {
        const output: any = {};
        output.question2 = "";
        output.question3 = "";
        output.question4 = "";
        if (showQuestion5) output.question5 = "";
        output.question6 = "";
        if (showQuestion7) output.question7 = "";
        output.question8 = "";
        if (showQuestion9) output.question9 = "";
        output.question10 = "";
        output.question11 = "";

        return output;
    };

    // Forms
    const {
        reset,
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
        resetField,
    } = useForm({ resolver: yupResolver(generateSchema()), defaultValues: generateDefaultValues() });

    const onSubmit = async (data: any) => {
        try {
            let points = 0;
            data = removeUndefinedInObject(dirtyValues(dirtyFields, data));
            setLoading(true);

            Object.entries(dirtyValues(dirtyFields, data)).forEach(e => {
                const localPoints = getScoreByQuestion("Sleep", parseInt((e[0] as string).replace("question", "")), dirtyValues(dirtyFields, data));
                if (typeof localPoints !== "undefined" && localPoints !== "n/a") points += localPoints as number;
            });

            // Question 5 skipped
            if (!data.question5) points += 2;
            // Question 7 skipped
            if (!data.question7) points += 2;
            // Question 9 skipped
            if (!data.question9) points += 2;

            enqueueSnackbar(t("questionnaireCompleted", { title: localStorage.getItem("language") === "en" ? TITLE_EN : TITLE_FR }), {
                variant: "success",
            });
            onComplete(TITLE_EN, dirtyValues(dirtyFields, data), points, "Sleep");
            window.scrollTo(0, 0);
            setIsSafeToReset(true);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.entries(errors).length > 0) {
            const id = Object.entries(errors)[0][0];
            const element = document.getElementById(id);

            if (element) element.scrollIntoView({ block: "center" });
        }
    }, [errors]);

    useEffect(() => {
        if (isSafeToReset) reset();
    }, [isSafeToReset]);

    return (
        <>
            <TopBarForm />
            <div className="forms__container">
                <Title>{localStorage.getItem("language") === "en" ? TITLE_EN : TITLE_FR}</Title>

                <div className="forms__section">
                    <h6 className="forms__subtitle">{localStorage.getItem("language") === "en" ? "About" : "À propos"}</h6>
                    <p>{localStorage.getItem("language") === "en" ? ABOUT_EN : ABOUT_FR}</p>
                </div>

                <div className="forms__section">
                    <h6 className="forms__subtitle">Instructions</h6>
                    <p>{localStorage.getItem("language") === "en" ? INSTRUCTION_EN : INSTRUCTION_FR}</p>
                </div>

                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e: any) => {
                        // Disable submit on "enter" key press
                        e.key === "Enter" && e.preventDefault();
                    }}
                    className="forms__content"
                >
                    <Grid item>
                        <div>
                            <div className="forms__important__container">
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "Think about your sleep over the last 3 months as you answer these questions. "
                                        : "Les questions suivantes portent sur votre sommeil au cours des trois derniers mois."}
                                </h6>
                            </div>

                            <Controller
                                control={control}
                                name="question2"
                                render={({ field }) => (
                                    <FormControl
                                        id="question2"
                                        component="fieldset"
                                        error={!!errors.question2?.message}
                                        {...field}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "How satisfied or dissatisfied were you with your current sleep pattern?"
                                                : "Dans quelle mesure avez-vous été satisfait ou insatisfait de votre rythme de sommeil ?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["radio"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value="Very satisfied"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Very satisfied" : "Très satisfait"}
                                            />
                                            <FormControlLabel
                                                value="Satisfied"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Satisfied" : "Satisfait"}
                                            />
                                            <FormControlLabel
                                                value="Neutral"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Neutral" : "Neutre"}
                                            />
                                            <FormControlLabel
                                                value="Dissatisfied"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Dissatisfied" : "Insatisfait"}
                                            />
                                            <FormControlLabel
                                                value="Very Dissatisfied"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Very Dissatisfied" : "Très insatisfait"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question2?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="question3"
                                    render={({ field }) => (
                                        <FormControl
                                            id="question3"
                                            component="fieldset"
                                            error={!!errors.question3?.message}
                                            {...field}
                                            className="forms__fullWidth"
                                        >
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "On average, how many hours of actual sleep did you get at night? (This may be different than the number of hours you spend in bed.)"
                                                    : "Quelle était la durée moyenne de votre sommeil chaque nuit? (Ceci peut être différent du nombre d'heures passé au lit.)"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["input"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <RadioGroup row className="forms__numberInput">
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    InputProps={{ inputProps: { min: 0, max: 24 } }}
                                                    variant="standard"
                                                    helperText={errors.question3?.message}
                                                    error={!!errors.question3?.message}
                                                    label={localStorage.getItem("language") === "en" ? "Hours per night" : "Heures par nuit"}
                                                    {...field}
                                                />
                                            </RadioGroup>
                                            <FormHelperText style={{ fontSize: 20 }}>{errors.question3?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="question4"
                                    render={({ field }) => (
                                        <FormControl
                                            id="question4"
                                            component="fieldset"
                                            error={!!errors.question4?.message}
                                            {...field}
                                            onChange={(e: any) => {
                                                field.onChange(e);
                                                resetField("question5");
                                                setShowQuestion5(
                                                    e.target.value === "Once or twice per week" ||
                                                        e.target.value === "Une ou deux fois par semaine" ||
                                                        e.target.value === "3-5 times/week" ||
                                                        e.target.value === "3-5 fois par semaine" ||
                                                        e.target.value === "6-7 times/week" ||
                                                        e.target.value === "6-7 fois par semaine"
                                                        ? true
                                                        : false
                                                );
                                            }}
                                            className="forms__fullWidth"
                                        >
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "How often did it take you more than 30 minutes to fall asleep?"
                                                    : "Combien de fois avez-vous eu besoin de plus de 30 minutes pour vous endormir?"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["radio"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <RadioGroup row className="forms__radioGroupContainer">
                                                <FormControlLabel
                                                    value="Never"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Never" : "Jamais"}
                                                />
                                                <FormControlLabel
                                                    value="<1 per week"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "<1 /week" : "<1 par semaine"}
                                                />
                                                <FormControlLabel
                                                    value="Once or twice per week"
                                                    control={<Radio />}
                                                    label={
                                                        localStorage.getItem("language") === "en"
                                                            ? "Once or twice per week"
                                                            : "Une ou deux fois par semaine"
                                                    }
                                                />
                                                <FormControlLabel
                                                    value="3-5 times/week"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "3-5 times/week" : "3-5 fois par semaine"}
                                                />
                                                <FormControlLabel
                                                    value="6-7 times/week"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "6-7 times/week" : "6-7 fois par semaine"}
                                                />
                                            </RadioGroup>
                                            <FormHelperText style={{ fontSize: 20 }}>{errors.question4?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            </Grid>

                            {showQuestion5 && (
                                <Controller
                                    control={control}
                                    name="question5"
                                    render={({ field }) => (
                                        <FormControl
                                            id="question5"
                                            component="fieldset"
                                            error={!!errors.question5?.message}
                                            {...field}
                                            className="forms__fullWidth"
                                        >
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "To what extent do you consider your problem falling asleep to interfere with your daily functioning (for example, from daytime fatigue, ability to function at work/daily chores, concentration, memory, mood, etc.)?"
                                                    : "Dans quelle mesure considérez-vous que votre problème d'endormissement interfère avec votre fonctionnement quotidien (par exemple, fatigue pendant la journée, capacité de fonctionner au travail ou lors des tâches quotidiennes, concentration, mémoire, humeur, etc.)?"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["radio"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <RadioGroup row className="forms__radioGroupContainer">
                                                <FormControlLabel
                                                    value="Never"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Never" : "Jamais"}
                                                />
                                                <FormControlLabel
                                                    value="A little"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "A little" : "Un peu"}
                                                />
                                                <FormControlLabel
                                                    value="Somewhat"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Somewhat" : "Moyen"}
                                                />
                                                <FormControlLabel
                                                    value="Much"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Much" : "Beaucoup"}
                                                />
                                                <FormControlLabel
                                                    value="Very much"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Very much" : "Considérablement"}
                                                />
                                            </RadioGroup>
                                            <FormHelperText style={{ fontSize: 20 }}>{errors.question5?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            )}

                            <Controller
                                control={control}
                                name="question6"
                                render={({ field }) => (
                                    <FormControl
                                        id="question6"
                                        component="fieldset"
                                        error={!!errors.question6?.message}
                                        {...field}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            resetField("question7");
                                            setShowQuestion7(
                                                e.target.value === "Once or twice/week" ||
                                                    e.target.value === "1-2 fois par semaine" ||
                                                    e.target.value === "3-5 times/week" ||
                                                    e.target.value === "3-5 fois par semaine" ||
                                                    e.target.value === "6-7 times/week" ||
                                                    e.target.value === "6-7 fois par semaine"
                                                    ? true
                                                    : false
                                            );
                                        }}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "How often did you wake in the middle of the night or too early in the morning and found it difficult to fall asleep again?"
                                                : "Combien de fois vous êtes-vous réveillé au milieu de la nuit ou trop tôt le matin, et avez eu de la difficulté à vous rendormir?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["radio"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value="Never"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Never" : "Jamais"}
                                            />
                                            <FormControlLabel
                                                value="<1 / week"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "<1 /week" : "<1 fois par semaine"}
                                            />
                                            <FormControlLabel
                                                value="Once or twice/week"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Once or twice/week" : "1-2 fois par semaine"}
                                            />
                                            <FormControlLabel
                                                value="3-5 times/week"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "3-5 times/week" : "3-5 fois par semaine"}
                                            />
                                            <FormControlLabel
                                                value="6-7 times/week"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "6-7 times/week" : "6-7 fois par semaine"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question6?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            {showQuestion7 && (
                                <Controller
                                    control={control}
                                    name="question7"
                                    render={({ field }) => (
                                        <FormControl
                                            id="question7"
                                            component="fieldset"
                                            error={!!errors.question7?.message}
                                            {...field}
                                            className="forms__fullWidth"
                                        >
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "To what extent do you consider your problem staying asleep to interfere with your daily functioning (for example, from daytime fatigue, ability to function at work/daily chores, concentration, memory, mood, etc.)?"
                                                    : "Dans quelle mesure considérez-vous que vos difficultés à rester endormi interfèrent avec votre fonctionnement quotidien (par exemple, fatigue pendant la journée, capacité de fonctionner au travail ou lors des tâches quotidiennes, concentration, mémoire, humeur, etc.)"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["input"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <RadioGroup row className="forms__radioGroupContainer">
                                                <FormControlLabel
                                                    value="Not at all"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Not at all" : "Jamais"}
                                                />
                                                <FormControlLabel
                                                    value="A little"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "A little" : "Un peu"}
                                                />
                                                <FormControlLabel
                                                    value="Somewhat"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Somewhat" : "Moyen"}
                                                />
                                                <FormControlLabel
                                                    value="Much"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Much" : "Beaucoup"}
                                                />
                                                <FormControlLabel
                                                    value="Very much"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Very much" : "Considérablement"}
                                                />
                                            </RadioGroup>
                                            <FormHelperText style={{ fontSize: 20 }}>{errors.question7?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            )}

                            <Controller
                                control={control}
                                name="question8"
                                render={({ field }) => (
                                    <FormControl
                                        id="question8"
                                        component="fieldset"
                                        error={!!errors.question8?.message}
                                        {...field}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            resetField("question9");
                                            setShowQuestion9(
                                                e.target.value === "Once or twice/week" ||
                                                    e.target.value === "1-2 fois par semaine" ||
                                                    e.target.value === "3-5 times/week" ||
                                                    e.target.value === "3-5 fois par semaine" ||
                                                    e.target.value === "6-7 times/week" ||
                                                    e.target.value === "6-7 fois par semaine"
                                                    ? true
                                                    : false
                                            );
                                        }}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "How often do you find it difficult to stay awake during your normal waking hours when you want to?"
                                                : "Combien de fois avez-vous eu de la difficulté à rester éveillé pendant le jour (lorsque désiré)?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["radio"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value="Never"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Never" : "Jamais"}
                                            />
                                            <FormControlLabel
                                                value="<1/week"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "<1 /week" : "<1 fois par semaine"}
                                            />
                                            <FormControlLabel
                                                value="Once or twice/week"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Once or twice/week" : "1-2 fois par semaine"}
                                            />
                                            <FormControlLabel
                                                value="3-5 times/week"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "3-5 times/week" : "3-5 fois par semaine"}
                                            />
                                            <FormControlLabel
                                                value="6-7 times/week"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "6-7 times/week" : "6-7 fois par semaine"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question8?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            {showQuestion9 && (
                                <Controller
                                    control={control}
                                    name="question9"
                                    render={({ field }) => (
                                        <FormControl
                                            id="question9"
                                            component="fieldset"
                                            error={!!errors.question9?.message}
                                            {...field}
                                            className="forms__fullWidth"
                                        >
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "To what extent do you consider your problem staying awake to interfere with your daily functioning?"
                                                    : "À quel point considérez-vous que vos difficultés à rester éveillé pendant le jour affectent de façon négative votre fonctionnement quotidien?"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["radio"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <RadioGroup row className="forms__radioGroupContainer">
                                                <FormControlLabel
                                                    value="Never"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Never" : "Jamais"}
                                                />
                                                <FormControlLabel
                                                    value="Not at all"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Not at all" : "Pas du tout"}
                                                />
                                                <FormControlLabel
                                                    value="A little"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "A little" : "Un peu"}
                                                />
                                                <FormControlLabel
                                                    value="Somewhat"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Somewhat" : "Moyen"}
                                                />
                                                <FormControlLabel
                                                    value="Much"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Much" : "Beaucoup"}
                                                />
                                                <FormControlLabel
                                                    value="Very much"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Very much" : "Considérablement"}
                                                />
                                            </RadioGroup>
                                            <FormHelperText style={{ fontSize: 20 }}>{errors.question9?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            )}

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="question10"
                                    render={({ field }) => (
                                        <FormControl
                                            id="question10"
                                            component="fieldset"
                                            error={!!errors.question10?.message}
                                            {...field}
                                            className="forms__fullWidth"
                                        >
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "Did you usually feel rested when you woke up in the morning?"
                                                    : "Vous sentiez-vous reposé en vous réveillant le matin?"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["radio"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <RadioGroup row className="forms__radioGroupContainer">
                                                <FormControlLabel
                                                    value="Not rested at all"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Not rested at all" : "Pas du tout reposé"}
                                                />
                                                <FormControlLabel
                                                    value="Slightly rested"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Slightly rested" : "Légèrement reposé"}
                                                />
                                                <FormControlLabel
                                                    value="Rested"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Rested" : "Reposé"}
                                                />
                                                <FormControlLabel
                                                    value="Well rested"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Well rested" : "Bien reposé"}
                                                />
                                                <FormControlLabel
                                                    value="Very well rested"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Very well rested" : "Très bien reposé"}
                                                />
                                            </RadioGroup>
                                            <FormHelperText style={{ fontSize: 20 }}>{errors.question10?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            </Grid>

                            <Controller
                                control={control}
                                name="question11"
                                render={({ field }) => (
                                    <FormControl
                                        id="question11"
                                        component="fieldset"
                                        error={!!errors.question11?.message}
                                        {...field}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "How much time did you usually spend napping every day?"
                                                : "Combien de temps passiez-vous à faire la sieste chaque jour?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["input"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__numberInput">
                                            <TextField
                                                type="number"
                                                fullWidth
                                                InputProps={{ inputProps: { min: 0, max: 1440 } }}
                                                variant="standard"
                                                helperText={errors.question11?.message}
                                                error={!!errors.question11?.message}
                                                label={<Typography variant="h6">Minutes</Typography>}
                                                {...field}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question11?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" type="submit" className="questionnaires__cta" fullWidth>
                            {localStorage.getItem("language") === "en" ? "Submit" : "Soumettre"} <MdKeyboardArrowRight />
                        </Button>
                    </Grid>
                    <div id="spacer_for_button" style={{ minHeight: 30 }} />
                </form>
            </div>
            <InstructionDialog open={dialogOpen} setOpen={setDialogOpen} types={selectedTypes} />
        </>
    );
};

export default SleepForm;
