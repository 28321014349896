import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { getDoc, doc as firebaseDoc, updateDoc } from "firebase/firestore";
import { genDoc } from "../../../firebase/requests";

// Assets
import trophy from "../../../assets/gifs/Trophy.gif";

// interfaces
import { completeChapter, isChapterCompleted } from "../../../utils/chapter";
import { Participant } from "../../../interfaces/Participant";

const CompleteChapter: React.FC = () => {
    console.log("CompleteChapter");

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const hist = useHistory();
    const { chapterId, isLibrary } = useParams<{ chapterId: string; isLibrary: string }>();
    const { currentParticipant, currentWeek } = useFetchUser();

    const handleComplete = async () => {
        if (currentParticipant && currentParticipant.id) {
            if (
                chapterId !== "L9JEcW0LVHgdT90SfPmi" &&
                chapterId !== "dBjDojoMPDWv3gvJCDjt" &&
                chapterId !== "h1BYNJTOhheE8tJ1khUk" &&
                isChapterCompleted(chapterId, currentParticipant)
            ) {
                console.log("should be here");

                if (isLibrary === "true") {
                    return hist.push("/library");
                }
                hist.push("/home");
            } else {
                console.log("before completeChapter");
                await completeChapter(currentParticipant.id, currentWeek, chapterId);
                console.log("after completeChapter");
                updateCompletedChapterCount();
                enqueueSnackbar(
                    <span>
                        {t(`1pointCompletedGained`)}
                        <b> 1 point </b>
                        {t(`1pointCompletedChapter`)}
                    </span>,
                    {
                        variant: "success",
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom",
                        },
                        content: (key, message) => (
                            <div key={key} className="point__snackbar">
                                <img alt="trophy" src={trophy} />
                                <p>{message}</p>
                            </div>
                        ),
                        autoHideDuration: 5000,
                    }
                );
                await redirect();
            }
        }
    };

    const redirect = async () => {
        try {
            if (isLibrary === "true") {
                return hist.push("/library");
            }
            if (chapterId === "z9FrQXipd4aNRgDmTzXC") {
                // if chapter 0 go to questionnaires stage 1
                hist.push("/questionnaires/stage1");
            } else if (chapterId === "L9JEcW0LVHgdT90SfPmi") {
                if (currentParticipant && !currentParticipant.goals["stage1"].length) {
                    // if chapter 5 go to overview
                    hist.push("/overview");
                } else hist.push("/home");
            } else if (chapterId === "dBjDojoMPDWv3gvJCDjt") {
                // if chapter 6.1 go to prioritizedTopics
                hist.push("/prioritizedTopics");
            } else if (chapterId === "h1BYNJTOhheE8tJ1khUk") {
                // if chapter 6.2 go to goals
                hist.push("/goals");
            } else {
                hist.push("/home");
            }
        } catch (e) {
            console.error(e);
        }
    };

    const updateCompletedChapterCount = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.uses) {
            const data = await getDoc(firebaseDoc(firestore, "Participants", currentParticipant.id));
            const participant = genDoc<Participant>()(data);

            if (participant && participant.id) {
                const index = participant.uses.findIndex(u => !u.end);
                if (index !== -1) {
                    if (participant.uses[index]) {
                        if (participant.uses[index].chaptersCompleted) {
                            participant.uses[index].chaptersCompleted.push(chapterId);
                        } else {
                            participant.uses[index].chaptersCompleted = [chapterId];
                        }
                        await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                            uses: [...participant.uses],
                        });
                    }
                }
            }
        }
    };

    useEffect(() => {
        handleComplete();
    }, []);

    return <></>;
};

export default CompleteChapter;
