import React, { useEffect, useState } from "react";
import moment from "moment";

// Mui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

// Components
import SearchBar from "../../../general/SearchBar";
import TableLoader from "../../../general/TableLoader";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { genDoc } from "../../../../firebase/requests";

// Hooks
import useDb from "../../../../hooks/useDb";
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Utils
import settings from "../../../../settings.json";
import { exportAOADocument } from "../../../../utils/xlsx";
import { getGaugeInfoAndDangerLevel } from "../../../../utils/topic";
import { calculateAge, formatDateIntoElapsedTime } from "../../../../utils/date";
import { getStageDisplayName } from "../../../../utils/general";
import {
    acceptabilityAllowedQuestionIndexes,
    attitudesTowardDementiaAllowedQuestionIndexes,
    cognitiveEngagementAllowedQuestionIndexes,
    dementiaLiteracyAllowedQuestionIndexes,
    getScoreByQuestion,
    physicalAllowedQuestionIndexes,
    selfEfficacyAllowedQuestionIndexes,
    sleepAllowedQuestionIndexes,
    socialPsychologicalAllowedQuestionIndexes,
    usabilityAllowedQuestionIndexes,
    vascularHealthAllowedQuestionIndexes,
    visionHearingAllowedQuestionIndexes,
    yourNutritionAllowedQuestionIndexes,
} from "../../../../utils/score";

// Interfaces
import { Stat } from "../../../../interfaces/Stat";
import { Participant } from "../../../../interfaces/Participant";

const Stats: React.FC = () => {
    const { currentAdmin, setLoading, loading } = useFetchUser();
    const { getById } = useDb<Participant>("Participants", currentAdmin, null);

    // States
    const [stats, setStats] = useState<Stat[]>([]);
    const [page, setPage] = useState(0);
    const [participants, setParticipants] = useState<Participant[]>([]);

    const fetchStats = async () => {
        setLoading(true);
        try {
            if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                const payload = await getDocs(query(collection(firestore, "Stats"), orderBy("createdAt", "desc")));
                setStats(
                    payload.docs
                        .map(genDoc<Stat>())
                        .filter(stat => participants.find(participant => stat.participantId === participant?.id)?.pscid?.includes("syn2"))
                        .filter(() => {
                            if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                                return true;
                            }
                            return false;
                        })
                        .filter((item: Stat) => {
                            const part = participants.find(p => p.id === item.participantId);
                            if (part) return !!part.pscid;
                            return false;
                        })
                );
            } else if (currentAdmin) {
                const payload = await getDocs(query(collection(firestore, "Stats"), orderBy("createdAt", "desc")));
                setStats(
                    payload.docs
                        .map(genDoc<Stat>())
                        .filter((item: Stat) => {
                            const part = participants.find(p => p.id === item.participantId);
                            if (part) return !!part.pscid;
                            return false;
                        })
                        .filter(item => {
                            const part = participants.find(p => p.id === item.participantId);
                            if (part && part.study === "synergic2") return true;
                            return false;
                        })
                );
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (e: any, newPage: number) => {
        setPage(newPage);
    };

    const fetchParticipants = async () => {
        try {
            setLoading(true);
            const payload = await getDocs(collection(firestore, "Participants"));
            setParticipants(payload.docs.map(genDoc<Participant>()));
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const convertPilotToPscid = async (participantId: string) => {
        let pscid = "pilot";

        const part = await getById(participantId);

        const data = [
            { email: "ageorge08@yahoo.ca", pscid: "byc2438" },
            { email: "bi0nicear@yahoo.com", pscid: "nbp4806" },
            { email: "bonnie.flatt@gmail.com", pscid: "byc0122" },
            { email: "bproctor1709@gmail.com", pscid: "ubr8254" },
            { email: "fdiamondcooper@gmail.com", pscid: "byc8245" },
            { email: "jbertin@xplornet.com", pscid: "nbp7256" },
            { email: "jimpark54@shaw.ca", pscid: "ubr9547" },
            { email: "joel@joelhertz.ca", pscid: "byc0303" },
            { email: "margaret_fraser@telus.net", pscid: "ubr4621" },
            { email: "nancyjc88@gmail.com", pscid: "ubr9601" },
            { email: "nchamann@rogers.com", pscid: "nbp6321" },
            { email: "ogeiger@telus.net", pscid: "ubr9381" },
            { email: "olin@triumf.ca", pscid: "ubr0653" },
            { email: "pkperry@nb.sympatico.ca", pscid: "nbp0509" },
            { email: "richard.dopson@gmail.com", pscid: "ubr0654" },
            { email: "robinburton415@gmail.com", pscid: "nbp8509" },
            { email: "sdorren@nbnet.nb.ca", pscid: "nbp9595" },
            { email: "sssstapleton@gmail.com", pscid: "byc1506" },
            { email: "toni.at.toronto@gmail.com", pscid: "byc4835" },
            { email: "z.rajwani@outlook.com", pscid: "byc2388" },
        ];

        Object.values(data).map(v => {
            if (v.email === (part as Participant).email) {
                pscid = v.pscid;
            }
        });

        return pscid;
    };

    const getKey = (q: string, a: string, t: string) => {
        if (t === "Acceptability") {
            switch (a) {
                default:
                case "Strongly disagree":
                case "Fortement en désaccord":
                    return 0;
                case "Moderately disagree":
                case "Modérément en désaccord":
                    return 1;
                case "Somewhat disagree":
                case "Plutôt en désaccord":
                    return 2;
                case "Neutral":
                case "Neutre":
                    return 3;
                case "Somewhat agree":
                case "Plutôt d’accord":
                    return 4;
                case "Moderately agree":
                case "Modérément d’accord":
                    return 5;
                case "Strongly agree":
                case "Fortement d’accord":
                    return 6;
            }
        }
        if (t === "Attitudes towards dementia") {
            switch (a) {
                default:
                case "Strongly agree":
                case "Tout à fait d'accord":
                    return 0;
                case "Agree":
                case "D'accord":
                    return 1;
                case "I don’t know":
                case "Je ne sais pas":
                    return 2;
                case "Disagree":
                case "En désaccord":
                    return 3;
                case "Strongly disagree":
                case "Tout à fait en désaccord":
                    return 4;
            }
        }
        if (t === "Cognitive") {
            switch (a) {
                default:
                case "Less than once per week":
                case "Moins d’une fois par semaine":
                    return 0;
                case "1-4 times per week":
                case "D'accord":
                    return 1;
                case "More than 5 times per week (almost every day)":
                case "Plus de 5 fois par semaine (presque chaque jour)":
                    return 2;
            }
        }
        if (t === "Dementia Literacy") {
            switch (a) {
                default:
                case "True":
                case "Vrai":
                    return 0;
                case "False":
                case "Faux":
                    return 1;
            }
        }
        if (t === "Nutrition") {
            let key = 0;

            if (q === "1" || q === "2") {
                switch (a) {
                    case "< 1 per day":
                    case "< 1 par jour":
                        key = 0;
                        break;
                    case "1 per day":
                    case "1 par jour":
                        key = 1;
                        break;
                    case "2 per day":
                    case "2 par jour":
                        key = 2;
                        break;
                    case "3 per day":
                    case "3 par jour":
                        key = 3;
                        break;
                    case "4 per day":
                    case "4 par jour":
                        key = 4;
                        break;
                    case "5 per day":
                    case "5 par jour":
                        key = 5;
                        break;
                    case "6 or more per day":
                    case "6 ou plus par jour":
                        key = 6;
                        break;
                }
            }
            if (q === "3" || q === "4" || q === "11") {
                switch (a) {
                    default:
                    case "< 1 per week":
                    case "< 1 par semaine":
                        key = 0;
                        break;
                    case "1 per week":
                    case "1 par semaine":
                        key = 1;
                        break;
                    case "2-3 per week":
                    case "2-3 par semaine":
                        key = 2;
                        break;
                    case "4-6 per week":
                    case "4-6 par semaine":
                        key = 3;
                        break;
                    case "1 per day":
                    case "1 par jour":
                        key = 4;
                        break;
                    case "2 or more per day":
                    case "2 ou plus par jour":
                        key = 5;
                        break;
                }
            }
            if (q === "5") {
                switch (a) {
                    default:
                    case "< 1 per week":
                    case "< 1 par semaine":
                        key = 0;
                        break;
                    case "1 per week":
                    case "1 par semaine":
                        key = 1;
                        break;
                    case "3 per week":
                    case "3 par semaine":
                        key = 2;
                        break;
                    case "4-6 per week":
                    case "4-6 par semaine":
                        key = 3;
                        break;
                    case "1 per day":
                    case "1 par jour":
                        key = 4;
                        break;
                    case "2 or more per day":
                    case "2 ou plus par jour":
                        key = 5;
                        break;
                }
            }
            if (q === "6" || q === "7") {
                switch (a) {
                    default:
                    case "Yes":
                    case "Oui":
                        key = 0;
                        break;
                    case "No":
                    case "Non":
                        key = 1;
                        break;
                    case "On most days":
                    case "La plupart des jours":
                        key = 2;
                        break;
                }
            }
            if (q === "8") {
                switch (a) {
                    default:
                    case "Yes":
                    case "Oui":
                        key = 0;
                        break;
                    case "No":
                    case "Non":
                        key = 1;
                        break;
                }
            }
            if (q === "9" || q === "10") {
                switch (a) {
                    default:
                    case "< 1 per week":
                    case "< 1 par semaine":
                        key = 0;
                        break;
                    case "1-3 per week":
                    case "1-3 par semaine":
                        key = 1;
                        break;
                    case "2-3 per week":
                    case "2-3 par semaine":
                        key = 2;
                        break;
                    case "4-6 per week":
                    case "4-6 par semaine":
                        key = 3;
                        break;
                    case "1 per day":
                    case "1 par jour":
                        key = 4;
                        break;
                    case "2 per day":
                    case "2 par jour":
                        key = 5;
                        break;
                    case "3 or more per day":
                    case "3 ou plus par jour":
                        key = 6;
                        break;
                }
            }

            return key;
        }
        if (t === "Physical") {
            if (q === "question3" || q === "question7" || q === "question11") {
                switch (a) {
                    case "Yes":
                    case "Oui":
                        return 0;
                    case "No":
                    case "Non":
                        return 1;
                }
            }
            if (
                q === "question4" ||
                q === "question5" ||
                q === "question8" ||
                q === "question9" ||
                q === "question12" ||
                q === "question13" ||
                q === "question15"
            ) {
                return 0;
            }
        }
        if (t === "Self Efficacy") {
            switch (a) {
                default:
                case "Not at all true":
                case "Pas du tout vrai":
                    return 0;
                case "Hardly true":
                case "À peine vrai":
                    return 1;
                case "Moderately true":
                case "Modérément vrai":
                    return 2;
                case "Exactly true":
                case "Tout à fait vrai":
                    return 3;
            }
        }
        if (t === "Sleep") {
            if (q === "question2") {
                switch (a) {
                    case "Very satisfied":
                    case "Très satisfait":
                        return 0;
                    case "Satisfied":
                    case "Satisfait":
                        return 1;
                    case "Neutral":
                    case "Neutre":
                        return 2;
                    case "Dissatisfied":
                    case "Insatisfait":
                        return 3;
                    case "Very Dissatisfied":
                    case "Très insatisfait":
                        return 4;
                }
            }
            if (q === "question3" || q === "question11") {
                return 0;
            }
            if (q === "question4") {
                switch (a) {
                    default:
                    case "Never":
                    case "Jamais":
                        return 0;
                    case "<1 /week":
                    case "<1 par semaine":
                        return 1;
                    case "Once or twice per week":
                    case "Une ou deux fois par semaine":
                        return 2;
                    case "3-5 times/week":
                    case "3-5 fois par semaine":
                        return 3;
                    case "6-7 times/week":
                    case "6-7 fois par semaine":
                        return 4;
                }
            }
            if (q === "question5" || q === "question7") {
                switch (a) {
                    default:
                    case "Never":
                    case "Jamais":
                        return 0;
                    case "A little":
                    case "Un peu":
                        return 1;
                    case "Somewhat":
                    case "Moyen":
                        return 2;
                    case "Much":
                    case "Beaucoup":
                        return 3;
                    case "Very much":
                    case "Considérablement":
                        return 4;
                }
            }
            if (q === "question6" || q === "question8") {
                switch (a) {
                    default:
                    case "Never":
                    case "Jamais":
                        return 0;
                    case "<1 /week":
                    case "<1 fois par semaine":
                        return 1;
                    case "Once or twice/week":
                    case "1-2 fois par semaine":
                        return 2;
                    case "3-5 times/week":
                    case "3-5 fois par semaine":
                        return 3;
                    case "6-7 times/week":
                    case "6-7 fois par semaine":
                        return 4;
                }
            }
            if (q === "question9") {
                switch (a) {
                    default:
                    case "Never":
                    case "Jamais":
                        return 0;
                    case "Not at all":
                    case "Pas du tout":
                        return 1;
                    case "A little":
                    case "Un peu":
                        return 2;
                    case "Somewhat":
                    case "Moyen":
                        return 3;
                    case "Much":
                    case "Beaucoup":
                        return 4;
                    case "Very much":
                    case "Considérablement":
                        return 5;
                }
            }
            if (q === "question10") {
                switch (a) {
                    default:
                    case "Not rested at all":
                    case "Pas du tout reposé":
                        return 0;
                    case "Slightly rested":
                    case "Légèrement reposé":
                        return 1;
                    case "Rested":
                    case "Reposé":
                        return 2;
                    case "Well rested":
                    case "Bien reposé":
                        return 3;
                    case "Very well rested":
                    case "Très bien reposé":
                        return 4;
                }
            }
        }
        if (t === "Social Psychological") {
            if (q === "question3") {
                return 0;
            } else {
                return parseInt(a);
            }
        }
        if (t === "Vascular Health") {
            if (q === "question12") {
                return 0;
            } else {
                switch (a) {
                    default:
                    case "No":
                    case "Non":
                        return 0;
                    case "Yes":
                    case "Oui":
                        return 1;
                }
            }
        }
        if (t === "Vision Hearing") {
            if (q === "0" || q === "1") {
                switch (a) {
                    case "Excellent / Very Good":
                    case "Excellente / Très bonne":
                        return 0;
                    case "Good":
                    case "Bonne":
                        return 1;
                    case "Fair / Poor":
                    case "Moyenne / Faible":
                        return 2;
                }
            }
            if (q === "2" || q === "3" || q === "4" || q === "5" || q === "6" || q === "7" || q === "8" || q === "9") {
                switch (a) {
                    case "Yes":
                    case "Oui":
                        return 0;
                    case "Sometimes":
                    case "Parfois":
                        return 1;
                    case "No":
                    case "Non":
                        return 2;
                }
            }
        }
        if (t === "Usability") {
            if (q === "1") {
                switch (a) {
                    default:
                    case "Less than once per month":
                    case "Moins d’une fois par mois":
                        return 0;
                    case "About once per week":
                    case "Environ une fois par semaine":
                        return 1;
                    case "1-3 times per week":
                    case "1-3 fois par semaine":
                        return 2;
                    case "4-7 times per week":
                    case "4-7 fois par semaine":
                        return 3;
                }
            }
            if (q === "2" || q === "3" || q === "4" || q === "5" || q === "6" || q === "7" || q === "8" || q === "9" || q === "10" || q === "11") {
                switch (a) {
                    default:
                    case "Strongly disagree":
                    case "Fortement en désaccord":
                        return 0;
                    case "Somewhat disagree":
                    case "Plutôt en désaccord":
                        return 1;
                    case "Neutral":
                    case "Neutre":
                        return 2;
                    case "Somewhat agree":
                    case "Plutôt d’accord":
                        return 3;
                    case "Strongly agree":
                    case "Fortement d’accord":
                        return 4;
                }
            }
        }
        return 0;
    };

    const formatData = async () => {
        const headers = ["Date", "PSCID", "Stage", "ParticipantId", "id", "Email"];

        const payload = await getDocs(collection(firestore, "Participants"));
        const participants = payload.docs.map(genDoc<Participant>()).filter(x => x.pscid?.includes("syn2"));

        // questionnaire count per topic

        // Add questions to the header
        for (let i = 0; i < acceptabilityAllowedQuestionIndexes.length; i++) {
            headers.push(`Acceptability Question ${acceptabilityAllowedQuestionIndexes[i]}`);
            headers.push(`Acceptability Question ${acceptabilityAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Acceptability Question ${acceptabilityAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Acceptability Score`);
        headers.push(`Acceptability Risk`);
        for (let i = 0; i < acceptabilityAllowedQuestionIndexes.length; i++)
            headers.push(`Acceptability Comment ${acceptabilityAllowedQuestionIndexes[i]}`);

        for (let i = 0; i < attitudesTowardDementiaAllowedQuestionIndexes.length; i++) {
            headers.push(`Attitudes towards dementia Question ${attitudesTowardDementiaAllowedQuestionIndexes[i]}`);
            headers.push(`Attitudes towards dementia Question ${attitudesTowardDementiaAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Attitudes towards dementia Question ${attitudesTowardDementiaAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Attitudes towards dementia Score`);

        for (let i = 0; i < cognitiveEngagementAllowedQuestionIndexes.length; i++) {
            headers.push(`Cognitive Question ${cognitiveEngagementAllowedQuestionIndexes[i]}`);
            headers.push(`Cognitive Question ${cognitiveEngagementAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Cognitive Question ${cognitiveEngagementAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Cognitive Score`);
        headers.push(`Cognitive Risk`);

        for (let i = 0; i < dementiaLiteracyAllowedQuestionIndexes.length; i++) {
            headers.push(`Dementia Literacy Question ${dementiaLiteracyAllowedQuestionIndexes[i]}`);
            headers.push(`Dementia Literacy Question ${dementiaLiteracyAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Dementia Literacy Question ${dementiaLiteracyAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Dementia Literacy Score`);

        for (let i = 0; i < yourNutritionAllowedQuestionIndexes.length; i++) {
            headers.push(`Nutrition Question ${yourNutritionAllowedQuestionIndexes[i]}`);
            headers.push(`Nutrition Question ${yourNutritionAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Nutrition Question ${yourNutritionAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Nutrition Score`);
        headers.push(`Nutrition Risk`);

        for (let i = 0; i < physicalAllowedQuestionIndexes.length; i++) {
            headers.push(`Physical Question ${physicalAllowedQuestionIndexes[i]}`);
            headers.push(`Physical Question ${physicalAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Physical Question ${physicalAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Physical Score`);
        headers.push(`Physical Risk`);

        for (let i = 0; i < selfEfficacyAllowedQuestionIndexes.length; i++) {
            headers.push(`Self Efficacy Question ${selfEfficacyAllowedQuestionIndexes[i]}`);
            headers.push(`Self Efficacy Question ${selfEfficacyAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Self Efficacy Question ${selfEfficacyAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Self Efficacy Score`);

        for (let i = 0; i < sleepAllowedQuestionIndexes.length; i++) {
            headers.push(`Sleep Question ${sleepAllowedQuestionIndexes[i]}`);
            headers.push(`Sleep Question ${sleepAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Sleep Question ${sleepAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Sleep Score`);
        headers.push(`Sleep Risk`);

        for (let i = 0; i < socialPsychologicalAllowedQuestionIndexes.length; i++) {
            headers.push(`Social Psychological Question ${socialPsychologicalAllowedQuestionIndexes[i]}`);

            // Adds age column
            if (socialPsychologicalAllowedQuestionIndexes[i] === 3) {
                headers.push(`Social Psychological Question ${socialPsychologicalAllowedQuestionIndexes[i]} (age)`);
            }
            headers.push(`Social Psychological Question ${socialPsychologicalAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Social Psychological Question ${socialPsychologicalAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Social Psychological Score`);
        headers.push(`Social Psychological Risk`);

        for (let i = 0; i < usabilityAllowedQuestionIndexes.length; i++) {
            headers.push(`Usability Question ${usabilityAllowedQuestionIndexes[i]}`);
            headers.push(`Usability Question ${usabilityAllowedQuestionIndexes[i]} (score)`);
        }
        for (let i = 0; i < usabilityAllowedQuestionIndexes.length; i++) headers.push(`Usability Comment ${usabilityAllowedQuestionIndexes[i]}`);
        headers.push(`Usability Score`);

        for (let i = 0; i < vascularHealthAllowedQuestionIndexes.length; i++) {
            headers.push(`Vascular Health Question ${vascularHealthAllowedQuestionIndexes[i]}`);
            headers.push(`Vascular Health Question ${vascularHealthAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Vascular Health Question ${vascularHealthAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Vascular Health Score`);
        headers.push(`Vascular Health Risk`);

        for (let i = 0; i < visionHearingAllowedQuestionIndexes.length; i++) {
            headers.push(`Vision Hearing Question ${visionHearingAllowedQuestionIndexes[i]}`);
            headers.push(`Vision Hearing Question ${visionHearingAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Vision Hearing Score`);
        headers.push(`Vision Hearing Risk`);

        let dataArray = [...Array(stats.length)].map(() => Array(headers.length).fill("-"));

        dataArray[0] = headers;

        for (const s of stats) {
            if (s.createdAt) {
                // stat is already in one of the rows
                let index = dataArray.findIndex(x => x[3] === s.participantId && x[2] === s.stage);

                // participant not found, find the next empty row
                if (index === -1) {
                    index = dataArray.findIndex(x => x[0] === "-");
                }

                // Skip if you're at the last row
                if (index === -1) {
                    continue;
                }

                const participant = participants.find(x => x.id === s.participantId);

                if (participant) {
                    const results = Object.entries(s.results);

                    dataArray[index][0] = moment(s.createdAt).format("DD/MM/YYYY HH:mm:ss");
                    dataArray[index][1] = getPscidFromParticipantId(s.participantId) ?? "-";
                    dataArray[index][2] = s.stage;
                    dataArray[index][3] = s.participantId;
                    dataArray[index][4] = s.id;
                    dataArray[index][5] = participant.email;

                    // Scores
                    const scoreIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Score`);
                    dataArray[index][scoreIndex] = s.score;

                    // Risk factors
                    const riskIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Risk`);
                    const { dangerLevel } = getGaugeInfoAndDangerLevel(s.topic, s.score);
                    dataArray[index][riskIndex] = dangerLevel;

                    for (let j = 0; j < results.length; j++) {
                        let headerIndex = -1;
                        const questionIndex = parseInt(results[j][0].replace("question", ""));

                        // check if questionIndex is allowed
                        if (
                            (s.topic === "Acceptability" && acceptabilityAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Attitudes towards dementia" && attitudesTowardDementiaAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Cognitive" && cognitiveEngagementAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Dementia Literacy" && dementiaLiteracyAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Nutrition" && yourNutritionAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Physical" && physicalAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Self Efficacy" && selfEfficacyAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Sleep" && sleepAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Social Psychological" && socialPsychologicalAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Usability" && usabilityAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Vascular Health" && vascularHealthAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Vision Hearing" && visionHearingAllowedQuestionIndexes.includes(questionIndex))
                        ) {
                            if (results[j][0].includes("question")) {
                                headerIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${questionIndex}`);

                                // Answer
                                dataArray[index][headerIndex] = results[j][1];

                                // Age
                                const ageIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${questionIndex} (age)`);
                                dataArray[index][ageIndex] = calculateAge(participant.dateOfBirth, s.createdAt);

                                // Key
                                const keyIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${questionIndex} (key)`);
                                dataArray[index][keyIndex] = getKey(results[j][0], results[j][1] as string, s.topic).toString();

                                // Score
                                const scoreIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${questionIndex} (score)`);
                                const score = getScoreByQuestion(s.topic, questionIndex, s.results, participant, s.createdAt);

                                dataArray[index][scoreIndex] = typeof score === "number" ? score : "n/a";
                                if (s.topic === "Sleep") {
                                    // empty sleeping question 5,7 and 9
                                    const score5Index = dataArray[0].findIndex((x: any) => x === `${s.topic} Question 5 (score)`);
                                    if (dataArray[index][score5Index] === "-") {
                                        const score5 = getScoreByQuestion(s.topic, 5, s.results, participant, s.createdAt);
                                        dataArray[index][score5Index] = typeof score5 === "number" ? score5 : "n/a";
                                    }

                                    const score7Index = dataArray[0].findIndex((x: any) => x === `${s.topic} Question 7 (score)`);
                                    if (dataArray[index][score7Index] === "-") {
                                        const score7 = getScoreByQuestion(s.topic, 7, s.results, participant, s.createdAt);
                                        dataArray[index][score7Index] = typeof score7 === "number" ? score7 : "n/a";
                                    }

                                    const score9Index = dataArray[0].findIndex((x: any) => x === `${s.topic} Question 9 (score)`);
                                    if (dataArray[index][score9Index] === "-") {
                                        const score9 = getScoreByQuestion(s.topic, 9, s.results, participant, s.createdAt);
                                        dataArray[index][score9Index] = typeof score9 === "number" ? score9 : "n/a";
                                    }
                                }
                            } else if (results[j][0].includes("comment")) {
                                const commentIndex = parseInt(results[j][0].replace("comment", ""));
                                headerIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Comment ${commentIndex}`);

                                // Answer
                                dataArray[index][headerIndex] = results[j][1];
                            } else {
                                headerIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${parseInt(results[j][0])}`);

                                // Answer
                                dataArray[index][headerIndex] = results[j][1];

                                // Key
                                const keyIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${parseInt(results[j][0])} (key)`);
                                dataArray[index][keyIndex] = getKey(results[j][0], results[j][1] as string, s.topic).toString();

                                // Score
                                const scoreIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${parseInt(results[j][0])} (score)`);
                                const score = getScoreByQuestion(s.topic, parseInt(results[j][0]), s.results, participant, s.createdAt);
                                dataArray[index][scoreIndex] = typeof score === "number" ? score : "n/a";
                            }
                        }
                    }
                }
            }
        }

        // Remove empty arrays from main array
        dataArray.forEach(d => {
            if (d && (!d[0] || d[0] === "-")) dataArray = dataArray.filter(x => x !== d);
        });

        return dataArray;
    };

    const formatPilotsData = async () => {
        const headers = ["Date", "PSCID", "Stage", "ParticipantId", "id", "Email"];

        const payload = await getDocs(collection(firestore, "Participants"));
        const participants = payload.docs.map(genDoc<Participant>()).filter(x => x.pscid?.includes("syn2"));

        // questionnaire count per topic

        // Add questions to the header
        for (let i = 0; i < acceptabilityAllowedQuestionIndexes.length; i++) {
            headers.push(`Acceptability Question ${acceptabilityAllowedQuestionIndexes[i]}`);
            headers.push(`Acceptability Question ${acceptabilityAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Acceptability Question ${acceptabilityAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Acceptability Score`);
        headers.push(`Acceptability Risk`);
        for (let i = 0; i < acceptabilityAllowedQuestionIndexes.length; i++)
            headers.push(`Acceptability Comment ${acceptabilityAllowedQuestionIndexes[i]}`);

        for (let i = 0; i < attitudesTowardDementiaAllowedQuestionIndexes.length; i++) {
            headers.push(`Attitudes towards dementia Question ${attitudesTowardDementiaAllowedQuestionIndexes[i]}`);
            headers.push(`Attitudes towards dementia Question ${attitudesTowardDementiaAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Attitudes towards dementia Question ${attitudesTowardDementiaAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Attitudes towards dementia Score`);

        for (let i = 0; i < cognitiveEngagementAllowedQuestionIndexes.length; i++) {
            headers.push(`Cognitive Question ${cognitiveEngagementAllowedQuestionIndexes[i]}`);
            headers.push(`Cognitive Question ${cognitiveEngagementAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Cognitive Question ${cognitiveEngagementAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Cognitive Score`);
        headers.push(`Cognitive Risk`);

        for (let i = 0; i < dementiaLiteracyAllowedQuestionIndexes.length; i++) {
            headers.push(`Dementia Literacy Question ${dementiaLiteracyAllowedQuestionIndexes[i]}`);
            headers.push(`Dementia Literacy Question ${dementiaLiteracyAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Dementia Literacy Question ${dementiaLiteracyAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Dementia Literacy Score`);

        for (let i = 0; i < yourNutritionAllowedQuestionIndexes.length; i++) {
            headers.push(`Nutrition Question ${yourNutritionAllowedQuestionIndexes[i]}`);
            headers.push(`Nutrition Question ${yourNutritionAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Nutrition Question ${yourNutritionAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Nutrition Score`);
        headers.push(`Nutrition Risk`);

        for (let i = 0; i < physicalAllowedQuestionIndexes.length; i++) {
            headers.push(`Physical Question ${physicalAllowedQuestionIndexes[i]}`);
            headers.push(`Physical Question ${physicalAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Physical Question ${physicalAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Physical Score`);
        headers.push(`Physical Risk`);

        for (let i = 0; i < selfEfficacyAllowedQuestionIndexes.length; i++) {
            headers.push(`Self Efficacy Question ${selfEfficacyAllowedQuestionIndexes[i]}`);
            headers.push(`Self Efficacy Question ${selfEfficacyAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Self Efficacy Question ${selfEfficacyAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Self Efficacy Score`);

        for (let i = 0; i < sleepAllowedQuestionIndexes.length; i++) {
            headers.push(`Sleep Question ${sleepAllowedQuestionIndexes[i]}`);
            headers.push(`Sleep Question ${sleepAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Sleep Question ${sleepAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Sleep Score`);
        headers.push(`Sleep Risk`);

        for (let i = 0; i < socialPsychologicalAllowedQuestionIndexes.length; i++) {
            headers.push(`Social Psychological Question ${socialPsychologicalAllowedQuestionIndexes[i]}`);
            headers.push(`Social Psychological Question ${socialPsychologicalAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Social Psychological Question ${socialPsychologicalAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Social Psychological Score`);
        headers.push(`Social Psychological Risk`);

        for (let i = 0; i < usabilityAllowedQuestionIndexes.length; i++) {
            headers.push(`Usability Question ${usabilityAllowedQuestionIndexes[i]}`);
            headers.push(`Usability Question ${usabilityAllowedQuestionIndexes[i]} (score)`);
        }
        for (let i = 0; i < usabilityAllowedQuestionIndexes.length; i++) headers.push(`Usability Comment ${usabilityAllowedQuestionIndexes[i]}`);
        headers.push(`Usability Score`);

        for (let i = 0; i < vascularHealthAllowedQuestionIndexes.length; i++) {
            headers.push(`Vascular Health Question ${vascularHealthAllowedQuestionIndexes[i]}`);
            headers.push(`Vascular Health Question ${vascularHealthAllowedQuestionIndexes[i]} (key)`);
            headers.push(`Vascular Health Question ${vascularHealthAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Vascular Health Score`);
        headers.push(`Vascular Health Risk`);

        for (let i = 0; i < visionHearingAllowedQuestionIndexes.length; i++) {
            headers.push(`Vision Hearing Question ${visionHearingAllowedQuestionIndexes[i]}`);
            headers.push(`Vision Hearing Question ${visionHearingAllowedQuestionIndexes[i]} (score)`);
        }
        headers.push(`Vision Hearing Score`);
        headers.push(`Vision Hearing Risk`);

        let dataArray = [...Array(stats.length)].map(() => Array(headers.length).fill("-"));

        dataArray[0] = headers;

        const partIds = [
            "V2vZmMrWRvN0ZY70JyRajvbionE2",
            "qlwGTyDPLeRfN97sT7lGa29WZbH2",
            "ubD5KmVOxudGvSb3z3Krr91iglO2",
            "aB8rmSTSOvgbLiUkAogmf14JHKs1",
            "n6KfLDxKMXduNtdynu0cnx5Su2l2",
            "2lT7CkVIjaeCWZWquBYx9trgFWn1",
            "aQKNOOA469Ysf7LlLNK6068hiol2",
            "auBSHlKkHneZ5hKS3GiHVc9sfKJ2",
            "p7ueGoWHaJN0twfz6z3imylMKMr2",
            "lAGQC0mboBTIUa3s4Gdg8LV1PD02",
            "sZ8KUe9TKeRFyNerZMf2nNFu2WH3",
            "Uvgx0ZzsYUgc0a8LEiIbVQ84yr92",
            "9BihNW1l60ZIEHCfWE1cC9x9cmI3",
            "aWbaVKfbcDgry0OEXjXjvhRHJLe2",
            "J2hLr3ttapgWDGQYqWfjitE3PPi2",
            "ykVvjJBbp1bkvQ1RyXdlqsICOV22",
            "Wkw3nRGCAfYkaAE4AXNDwBmd2yw1",
            "8uVSGZ9HZsX6BYaEr4iWFeazUtx1",
            "tEhgHwBazmSVbXUUP3j08kByH892",
            "18nG9uuHSON4Su6eQRczF3w0lp92",
            "qlwGTyDPLeRfN97sT7lGa29WZbH2",
            "auBSHlKkHneZ5hKS3GiHVc9sfKJ2",
            "aQKNOOA469Ysf7LlLNK6068hiol2",
            "lAGQC0mboBTIUa3s4Gdg8LV1PD02",
            "n6KfLDxKMXduNtdynu0cnx5Su2l2",
            "sZ8KUe9TKeRFyNerZMf2nNFu2WH3",
            "2lT7CkVIjaeCWZWquBYx9trgFWn1",
            "aB8rmSTSOvgbLiUkAogmf14JHKs1",
            "p7ueGoWHaJN0twfz6z3imylMKMr2",
            "ubD5KmVOxudGvSb3z3Krr91iglO2",
            "V2vZmMrWRvN0ZY70JyRajvbionE2",
            "8uVSGZ9HZsX6BYaEr4iWFeazUtx1",
            "J2hLr3ttapgWDGQYqWfjitE3PPi2",
            "aWbaVKfbcDgry0OEXjXjvhRHJLe2",
            "9BihNW1l60ZIEHCfWE1cC9x9cmI3",
            "ykVvjJBbp1bkvQ1RyXdlqsICOV22",
            "Uvgx0ZzsYUgc0a8LEiIbVQ84yr92",
            "tEhgHwBazmSVbXUUP3j08kByH892",
            "18nG9uuHSON4Su6eQRczF3w0lp92",
            "Wkw3nRGCAfYkaAE4AXNDwBmd2yw1",
        ];

        for (const s of stats.filter(s => partIds.includes(s.participantId))) {
            if (s.createdAt) {
                // part + stage already in array
                let index = dataArray.findIndex(x => x[3] === s.participantId && x[2] === s.stage);

                if (index === -1) {
                    index = dataArray.findIndex(x => x[0] === "-");
                }

                const participant = participants.find(x => x.id === s.participantId);

                if (participant) {
                    const results = Object.entries(s.results);

                    dataArray[index][0] = moment(s.createdAt).format("DD/MM/YYYY HH:mm:ss");
                    dataArray[index][1] = await convertPilotToPscid(s.participantId);
                    dataArray[index][2] = s.stage;
                    dataArray[index][3] = s.participantId;
                    dataArray[index][4] = s.id;
                    dataArray[index][5] = participant.email;

                    // Scores
                    const scoreIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Score`);
                    dataArray[index][scoreIndex] = s.score;
                    // Risk factors
                    const riskIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Risk`);
                    const { dangerLevel } = getGaugeInfoAndDangerLevel(s.topic, s.score);
                    dataArray[index][riskIndex] = dangerLevel;

                    for (let j = 0; j < results.length; j++) {
                        let headerIndex = -1;
                        const questionIndex = parseInt(results[j][0].replace("question", ""));

                        // check if questionIndex is allowed
                        if (
                            (s.topic === "Acceptability" && acceptabilityAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Attitudes towards dementia" && attitudesTowardDementiaAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Cognitive" && cognitiveEngagementAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Dementia Literacy" && dementiaLiteracyAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Nutrition" && yourNutritionAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Physical" && physicalAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Self Efficacy" && selfEfficacyAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Sleep" && sleepAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Social Psychological" && socialPsychologicalAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Usability" && usabilityAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Vascular Health" && vascularHealthAllowedQuestionIndexes.includes(questionIndex)) ||
                            (s.topic === "Vision Hearing" && visionHearingAllowedQuestionIndexes.includes(questionIndex))
                        ) {
                            if (results[j][0].includes("question")) {
                                headerIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${questionIndex}`);

                                // Key
                                const keyIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${questionIndex} (key)`);
                                dataArray[index][keyIndex] = getKey(results[j][0], results[j][1] as string, s.topic).toString();

                                // Score
                                const scoreIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${questionIndex} (score)`);
                                const score = getScoreByQuestion(s.topic, questionIndex, s.results, participant, s.createdAt);
                                dataArray[index][scoreIndex] = score ?? "n/a";
                                if (s.topic === "Sleep") {
                                    // empty sleeping question 5,7 and 9
                                    const score5Index = dataArray[0].findIndex((x: any) => x === `${s.topic} Question 5 (score)`);
                                    if (dataArray[index][score5Index] === "-") {
                                        const score5 = getScoreByQuestion(s.topic, 5, s.results, participant, s.createdAt);
                                        dataArray[index][score5Index] = score5 ?? "n/a";
                                    }

                                    const score7Index = dataArray[0].findIndex((x: any) => x === `${s.topic} Question 7 (score)`);
                                    if (dataArray[index][score7Index] === "-") {
                                        const score7 = getScoreByQuestion(s.topic, 7, s.results, participant, s.createdAt);
                                        dataArray[index][score7Index] = score7 ?? "n/a";
                                    }

                                    const score9Index = dataArray[0].findIndex((x: any) => x === `${s.topic} Question 9 (score)`);
                                    if (dataArray[index][score9Index] === "-") {
                                        const score9 = getScoreByQuestion(s.topic, 9, s.results, participant, s.createdAt);
                                        dataArray[index][score9Index] = score9 ?? "n/a";
                                    }
                                }
                            } else if (results[j][0].includes("comment")) {
                                const commentIndex = parseInt(results[j][0].replace("comment", ""));
                                headerIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Comment ${commentIndex}`);
                            } else {
                                headerIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${parseInt(results[j][0])}`);

                                // Key
                                const keyIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${parseInt(results[j][0])} (key)`);
                                dataArray[index][keyIndex] = getKey(results[j][0], results[j][1] as string, s.topic).toString();

                                // Score
                                const scoreIndex = dataArray[0].findIndex((x: any) => x === `${s.topic} Question ${parseInt(results[j][0])} (score)`);
                                const score = getScoreByQuestion(s.topic, parseInt(results[j][0]), s.results, participant, s.createdAt);
                                dataArray[index][scoreIndex] = score ?? "n/a";
                            }
                        }

                        if (headerIndex !== -1 && dataArray[index] && dataArray[index][headerIndex] === "-") {
                            dataArray[index][headerIndex] = results[j][1];
                        }
                    }
                }
            }
        }

        // Remove empty arrays from main array
        dataArray.forEach(d => {
            if (d && (!d[0] || d[0] === "-")) dataArray = dataArray.filter(x => x !== d);
        });

        return dataArray;
    };

    const exportPilotsToXLSX = async () => {
        setLoading(true);
        const fileName = `pilot_stats_${moment(new Date()).format("DD-MM-YYYY")}`;
        const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
        exportAOADocument(await formatPilotsData(), fileName, sheetName);
        setLoading(false);
    };

    const exportToXLSX = async () => {
        setLoading(true);
        const fileName = `stats_${moment(new Date()).format("DD-MM-YYYY")}`;
        const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
        exportAOADocument(await formatData(), fileName, sheetName);
        setLoading(false);
    };

    const getPscidFromParticipantId = (participantId: string) => {
        const part = participants.find(p => p.id === participantId);

        if (part) if (part.pscid) return part.pscid;
    };

    useEffect(() => {
        let unsub: any = null;

        if (participants && participants.length) {
            unsub = fetchStats();
        }

        return () => {
            typeof unsub === "function" && unsub();
        };
    }, [participants]);

    useEffect(() => {
        fetchParticipants();
    }, []);

    return (
        <>
            <Grid item container>
                <Grid item xs={8}>
                    <SearchBar title="Statistics" />
                </Grid>
                {currentAdmin?.roles.includes("superAdmin") && (
                    <Grid item xs={2}>
                        <Button
                            disabled={!stats.length}
                            variant="contained"
                            onClick={() => exportPilotsToXLSX()}
                            style={{ float: "right", marginTop: 20 }}
                        >
                            Export pilots
                        </Button>
                    </Grid>
                )}

                {currentAdmin?.roles.includes("superAdmin") && (
                    <Grid item xs={2}>
                        <Button disabled={!stats.length} variant="contained" onClick={() => exportToXLSX()} style={{ float: "right", marginTop: 20 }}>
                            Export
                        </Button>
                    </Grid>
                )}
            </Grid>

            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={stats.length}
                rowsPerPage={settings.page.rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
            />
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">PSCID</TableCell>
                        <TableCell align="center">Topic</TableCell>
                        <TableCell align="center">Stage</TableCell>
                        <TableCell align="center">Score</TableCell>
                        <TableCell align="center">Risk Factor</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stats && stats.length ? (
                        stats.slice(page * settings.page.rowsPerPage, page * settings.page.rowsPerPage + settings.page.rowsPerPage).map((row, i) => (
                            <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">{row.createdAt && formatDateIntoElapsedTime(row.createdAt)}</TableCell>
                                <TableCell align="center">{getPscidFromParticipantId(row.participantId)}</TableCell>
                                <TableCell align="center">{row.topic}</TableCell>
                                <TableCell align="center">{getStageDisplayName(row.stage)}</TableCell>
                                <TableCell align="center">{row.score}</TableCell>
                                <TableCell align="center">
                                    {getGaugeInfoAndDangerLevel(row.topic, row.score).dangerLevel
                                        ? getGaugeInfoAndDangerLevel(row.topic, row.score).dangerLevel
                                        : ""}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableLoader data={stats} loading={loading} noMoreData={true} />
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default Stats;
