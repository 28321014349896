import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import ResponsiveEmbed from "react-responsive-embed";
import { useTranslation } from "react-i18next";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";
import useDb from "../../../hooks/useDb";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { getDoc, doc as firebaseDoc, updateDoc } from "firebase/firestore";
import { genDoc } from "../../../firebase/requests";

// Mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

// Interfaces
import { Chapter as ChapterInterface } from "../../../interfaces/Chapter";
import { Participant } from "../../../interfaces/Participant";

// Utils
import { isChapterCompleted } from "../../../utils/chapter";

// Assets
import { MdClose } from "react-icons/md";

// Custom components
import TopBarForm from "../../pages/portal/forms/TopBarForm";
import Title from "../../general/Title";
import { translateTopic } from "../../../utils/topic";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Chapter: React.FC = () => {
    const { t } = useTranslation();
    const hist = useHistory();
    const { chapterId, hasMenu, isLibrary } = useParams<{ chapterId: string; hasMenu: string; isLibrary: string }>();
    const { currentParticipant } = useFetchUser();
    const { getById } = useDb<ChapterInterface>("Chapters", null, currentParticipant);

    const isFullscreen = hist.location.pathname.includes("fullscreen");
    const isFirstStep = chapterId === "firstStep";
    const isInteractiveActivities = chapterId === "interactiveActivities";

    // States
    const [loadedChapter, setLoadedChapter] = useState<ChapterInterface | null>(null);
    const [isIos, setIsIos] = useState(false);
    const [iosFullscreenDialogOpen, setIosFullscreenDialogOpen] = useState(false);

    const fetchChapter = async () => {
        if (chapterId !== "firstStep" && chapterId !== "interactiveActivities" && chapterId) {
            setLoadedChapter((await getById(chapterId)) as ChapterInterface);
        }
    };

    function iOS() {
        setIsIos(
            ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        );
    }

    const handleComplete = (e: any) => {
        e.preventDefault();
        let chapterIds: string[] = [];

        if (currentParticipant && loadedChapter && loadedChapter.id) {
            Object.values(currentParticipant.completedChapters).map(v => {
                chapterIds = [...chapterIds, ...v];
            });

            if (isLibrary) {
                hist.push(`/completed/${loadedChapter.id}/${isLibrary}`);
            } else {
                hist.push(`/completed/${loadedChapter.id}/`);
            }
        }
    };

    const updateVisitedChapterCount = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.uses) {
            const data = await getDoc(firebaseDoc(firestore, "Participants", currentParticipant.id));
            const participant = genDoc<Participant>()(data);

            if (participant && participant.id) {
                const index = participant.uses.findIndex(u => !u.end);
                if (index !== -1) {
                    if (participant.uses[index]) {
                        if (participant.uses[index].chaptersVisited) {
                            if (!participant.uses[index].chaptersVisited.includes(chapterId)) participant.uses[index].chaptersVisited.push(chapterId);
                        } else {
                            participant.uses[index].chaptersVisited = [chapterId];
                        }
                        await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                            uses: [...participant.uses],
                        });
                    }
                }
            }
        }
    };

    const getVideoSource = (loadedChapter: ChapterInterface) => {
        return localStorage.getItem("language") === "en" ? loadedChapter.linkEN : loadedChapter.linkFR;
    };

    useEffect(() => {
        fetchChapter();
        updateVisitedChapterCount();
        iOS();
    }, [chapterId]);

    const fetchSource = () => {
        let source = "";

        if (
            chapterId === "z9FrQXipd4aNRgDmTzXC" &&
            (hasMenu === "true" || (loadedChapter && loadedChapter.id && isChapterCompleted(loadedChapter.id, currentParticipant)))
        ) {
            source = `https://storylines.brainhealthpro.ca/Orientation/Module0-menu-${
                localStorage.getItem("language") === "en" ? "ENG" : "FR"
            }/story.html`;
        } else if (loadedChapter) source = getVideoSource(loadedChapter);

        return source;
    };

    const getSrc = () => {
        if (!isFirstStep && !isInteractiveActivities) {
            return fetchSource();
        }

        if (isFirstStep) {
            return `https://storylines.brainhealthpro.ca/Orientation/Module0-1S-menu-${
                localStorage.getItem("language") === "en" ? "ENG" : "FR"
            }/story.html`;
        }

        if (isInteractiveActivities) {
            return `https://storylines.brainhealthpro.ca/Orientation/Module0-IA-menu-${
                localStorage.getItem("language") === "en" ? "ENG" : "FR"
            }/story.html`;
        }
    };

    if (loadedChapter)
        return (
            <>
                {isFullscreen && <TopBarForm />}

                <div style={{ margin: 20 }}>
                    <Title>
                        {localStorage.getItem("language") === "en" ? loadedChapter.topic : translateTopic(loadedChapter.topic)} -{" "}
                        {!isFirstStep && !isInteractiveActivities
                            ? localStorage.getItem("language") === "en"
                                ? loadedChapter.titleEN
                                : loadedChapter.titleFR
                            : ""}
                        {isFirstStep ? (localStorage.getItem("language") === "en" ? "First Step" : "Premiers pas") : ""}
                        {isInteractiveActivities
                            ? localStorage.getItem("language") === "en"
                                ? "Interactive Activities"
                                : "Activités interactives"
                            : ""}
                    </Title>
                </div>

                <ResponsiveEmbed src={getSrc()} ratio="16:9" className="nofilterimg" allowFullScreen />

                {isIos && (
                    <Button variant="contained" onClick={() => setIosFullscreenDialogOpen(true)} className="questionnaires__cta" fullWidth>
                        {t("fullscreen")}
                    </Button>
                )}

                {isLibrary === "true" || window.location.href.includes("localhost:8283") ? (
                    <div style={{ display: "flex" }}>
                        <Button variant="contained" onClick={handleComplete} className="questionnaires__cta" fullWidth>
                            {t("skipChapter")}
                        </Button>
                        <Button variant="contained" onClick={() => hist.push("/library")} className="questionnaires__cta" fullWidth>
                            {t("back")}
                        </Button>
                    </div>
                ) : (
                    <></>
                )}

                <h3 className="questionnaire__bold">{t("questionnaireComplete")}</h3>

                <Dialog fullScreen open={iosFullscreenDialogOpen} onClose={() => setIosFullscreenDialogOpen(false)} TransitionComponent={Transition}>
                    <div style={{ position: "relative", height: 100 }}>
                        <div style={{ position: "absolute", top: 10, left: 15, zIndex: 1 }}>
                            <IconButton edge="start" color="inherit" onClick={() => setIosFullscreenDialogOpen(false)} aria-label="close">
                                <MdClose fill="#f59120" size={50} />
                            </IconButton>
                        </div>
                        <div>
                            <ResponsiveEmbed src={getSrc()} ratio="16:9" className="nofilterimg" allowFullScreen style={{ height: 100 }} />
                        </div>
                    </div>
                </Dialog>
            </>
        );

    return <></>;
};

export default Chapter;
